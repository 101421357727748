import type { ReactElement } from 'react';
import { MenuItem, TextField, Typography } from '@mui/material';
import type { TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronDown } from 'lucide-react';

export type PpSelect = {
  menuTitle?: string;
  options: Array<string>;
} & TextFieldProps;

const StyledMenuTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

export function Select({ menuTitle, options, placeholder, ...rest }: PpSelect): ReactElement {
  return (
    <TextField
      select
      {...rest}
      slotProps={{
        select: {
          displayEmpty: true,
          IconComponent: ChevronDown,
          MenuProps: {
            MenuListProps: {
              disablePadding: true,
            },
            PaperProps: {
              sx: {
                border: (theme) => `1px solid ${theme.palette.grayscale[300]}`,
                marginTop: 0.5,
              },
            },
          },
          renderValue: (value): React.ReactNode => {
            if (!value) {
              return (
                <Typography color="text.secondary" variant="paragraphBodyS">
                  {placeholder}
                </Typography>
              );
            }

            return value;
          },
        },
      }}
    >
      {menuTitle && <StyledMenuTitle variant="paragraphBodySBold">{menuTitle}</StyledMenuTitle>}
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
